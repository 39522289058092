import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Insta from "../images/instagram.png"
import Vk from "../images/vk.png"
import Logo from "../images/logo.svg"

const Header = () => (
  <header
    style={{
      background: `white`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 1200,
        padding: `1.05rem 1.0875rem`,
        display: `flex`,
        flexDirection: `row`,
        justifyContent: `space-between`,
      }}
    >
      <h4 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `black`,
            textDecoration: `none`,
          }}
        >
          <img className="h-20" src={Logo} alt="logo" />
        </Link>
      </h4>
      <div className="flex flex-row items-end">
        <a
          href="https://instagram.com/propisi.store?utm_medium=copy_link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="h-8 mr-2" src={Insta} alt="Instagram" />
        </a>
        <a
          href="https://vk.com/id669797369"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="h-8" src={Vk} alt="Vk" />
        </a>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
